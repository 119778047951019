(function($) {


/* scroll to calendar day */
jQuery(document).ready(function($) {

  // if the url is /kalender, scroll to the current day which is a div.calel.istoday element
  if (window.location.pathname == '/kalender' || window.location.pathname == 'kalender' || window.location.pathname == 'kalender/#' || window.location.pathname == '/kalender#' || window.location.pathname == '/kalender/') {
    var today = document.querySelector('div.calel.istoday');
    if (today) {
      // instead of  today.scrollIntoView(); scroll about 100 px above the today element
      window.scrollTo(0, today.offsetTop - 200);
    }
  }


// on #offeredcancelledtoggle click, toggle all the .offeredcancelled elements (between display:block and display:none), prevent default behaviour, and toggle strikethrough of .offeredcancelled

  jQuery('#offeredcancelledtoggle').click(function(e) {
    e.preventDefault();
    // if the .offercancelled elements are visible, hide them and remove the strikethrough class

    // first check if there are any .offeredcancelled elements
    if (jQuery('.offeredcancelled').length) {
  
      if (jQuery('.offeredcancelled').is(':visible')) {
        jQuery('.offeredcancelled').css('display', 'none');
        jQuery('#offeredcancelledtoggle').addClass('strikethrough');
        
      } else {
        jQuery('.offeredcancelled').css('display', 'block');
        
        jQuery('#offeredcancelledtoggle').removeClass('strikethrough');
      }

    } else {
      // if there are no .offeredcancelled elements, just remove the strikethrough class
      jQuery('#offeredcancelledtoggle').toggleClass('strikethrough');
    }
  }
  );
  

});


/* AJAX request */

if(jQuery('.facetwp-template').data('name') == 'events4' || jQuery('.facetwp-template').data('name') == 'events_admin') {

  document.addEventListener("DOMContentLoaded", function() {
    checksales();
  });

  jQuery(document).on('facetwp-loaded', function() {
    checksales();
  });
}

document.addEventListener("DOMContentLoaded", function() {

 if (window.location.pathname == '/kammgarn-kultur-listenansicht' || window.location.pathname == 'kammgarn-kultur-listenansicht' || window.location.pathname == 'kammgarn-kultur-listenansicht/#' || window.location.pathname == '/kammgarn-kultur-listenansicht#' || window.location.pathname == '/kammgarn-kultur-listenansicht/' ) {
  // delay 2 seconds then check sales:
  setTimeout(checksales, 2000);
 }

});

function checksales() {
  // Select only elements that are not already being processed
  var salesReports = document.querySelectorAll('.salesreport:not(.loading):not(.finished)');

  salesReports.forEach(function(report, index) {
    // Mark the element as "loading" immediately to prevent reprocessing
    report.classList.add('loading');

    // Store the eventID in a data attribute so that even if the text changes,
    // the original eventID is preserved
    if (!report.dataset.eventId) {
      report.dataset.eventId = report.textContent.trim();
    }
    var eventID = report.dataset.eventId;

    // If the text indicates there is no code, mark it finished and skip processing.
    if (eventID === 'kein Code') {
      report.classList.remove('loading');
      report.classList.add('finished');
      return;
    }

    // Use a delay to throttle API calls
    setTimeout(function() {
      var url = `/wp-content/themes/kammgarn/lib/apiproxy.php?eventID=${eventID}`;
      
      fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      })
      .then(response => response.json())
      .then(data => {
          // Check if the returned data is an error object
          if (typeof data === 'object' && data !== null && data.error) {
              console.error('Error fetching data for EventID ' + eventID + ': ' + data.error);
              report.textContent = 'Error';
          } else {
              report.textContent = data;
              //console.log('Data fetched: EventID ' + eventID + ' Sales: ' + data);
          }
          // Mark this report as finished
          report.classList.remove('loading');
          report.classList.add('finished');
      })
      .catch((error) => {
          console.log('Error: ' + error);
          report.textContent = 'Error';
          report.classList.remove('loading');
          report.classList.add('finished');
      });
    }, index * 100); // Adjust the delay as needed
  });
}

/* // AJAX request */




  /* admin toggle button */

  jQuery(document).ready(function($) {
    $(document).on('click', '.toggle-btn', function() {
      $(this).parent().toggleClass('expanded');
    });
  });
  
/* tag hinzufuegen */


if(jQuery('#parentevent').length) {


var url = window.location.href;

// Parse the URL to get its components
var urlObject = new URL(url);

// Access the search parameters (GET variables) from the URL object
var eventid = urlObject.searchParams.get("tag");

var eventid = parseFloat(eventid);

if (!isNaN(eventid) && eventid > 0) {
jQuery('#parentevent input').val(eventid);
//$("#parentevent input").prop("disabled", true);
} else {
  console.log('Event ID: ERROR');
}

  jQuery( document ).ready( function( $ ) {
    coverparentevent()
  });

  $(window).resize(function(){
    coverparentevent()
  });


  jQuery(window).scroll(function(){ 
    coverparentevent()
  });

  function coverparentevent() {
    var parentevent = $("#parentevent");
    var parentdivcover = $("#parentdivcover");

    // Set position, width, and height
    parentdivcover.css({
        'position': 'absolute',
        'top': parentevent.offset().top,
        'left': parentevent.offset().left,
        'width': parentevent.outerWidth(),
        'height': parentevent.outerHeight(),
        'background-color': 'rgba(255, 0, 0, 0.1)'
    });

    if(jQuery('#wpadminbar').length) {
      parentdivcover.css({
        'top': parentevent.offset().top - 32,
      });
    }
  }


}

jQuery( document ).ready( function( $ ) {


  /* check formlock */
  /* for every formlock element, hide "(data-lockelement) .acf-button.acf-repeater-add-row" */
  
  jQuery('.formlock').each(function() {
    var lockselector = $(this).data('lockselector');
    jQuery(lockselector + ' .acf-button.acf-repeater-add-row').hide();
    jQuery(lockselector + ' .acf-row-handle.remove').hide();
    jQuery(lockselector + ' .acf-row-handle.order').removeClass('order');
  });



  /* check if there's a h3.printschedule with a span called "rightx" and make it toggleable when clicking rightx (it has to check if it exists with length and it has to be an each statement */
  if(jQuery('h3.printschedule.hidemerightx').length) {
    jQuery('h3.printschedule.hidemerightx').each(function() {
      jQuery(this).click(function() {

        jQuery(this).toggle();
        jQuery(this).next().toggle();
      });
    });
  }

  if(jQuery('.entryleft.hidemerightx').length) {
    jQuery('.entryleft.hidemerightx').each(function() {
      jQuery(this).click(function() {

        jQuery(this).toggle();
        jQuery(this).next().toggle();
      });
    });
  }




  if(jQuery('.caltab').length) {

    // echo javascript code for popover

      $('[data-popover]').popover({
      html: true,
      placement: 'auto',
      trigger: 'hover',
      // get content from the .popover-content with id e + data-popover
      content: function () { return $('#e' + $(this).data('popover')).html(); }
      });

      // prevent default behaviour on a.kalenderlink with touch devices and follow link only after second click

      var isTouchDevice = 'ontouchstart' in document.documentElement;
      if (isTouchDevice) {
        $('.kalenderlink').click(function(e) {
        e.preventDefault();
        if ($(this).data('clicked')) {
        window.location = $(this).attr('href');
        } else {
        $(this).data('clicked', true);
        }
        });
      }


	
  }



  
  if(jQuery('#hatenddatum').length) {

    if(jQuery('#eventtagnr').length) { 

      jQuery('#hatenddatum').hide();

    }

  }
});


/*
if(jQuery('#hatenddatum').length) {
  if(jQuery('#addtag').length) { 
    jQuery('#hatenddatum .acf-true-false label').on('click', function() {
      if(jQuery('#hatenddatum .acf-true-false label .acf-switch.-on').length) {

        jQuery('#addtag').hide();
      } else {

        jQuery('#addtag').show();
      }
    });
  }
}
*/


if(jQuery('.facetwp-template').data('name') != 'events4' 
&& jQuery('.facetwp-template').data('name') != 'events_frontend_listenansicht'
&& jQuery('.facetwp-template').data('name') != 'eventliste_cancelled_admin') {

var $grid = $('.facetwp-template').masonry({
    // options...
    itemSelector: 'article',
  });


  $('.facetwp-template').imagesLoaded( function() {
    // images have loaded
    $grid.masonry("reloadItems");
      $grid.masonry("layout");
  });


jQuery(document).on('facetwp-loaded', function() {

  displaySuchfeld();
  $('.facetwp-template').imagesLoaded( function() {
    // images have loaded
    $grid.masonry("reloadItems");
      $grid.masonry("layout");
  });

});

}

if(jQuery('.facetwp-template-static').length) {

  console.log('static gefunden');

    var $grid = $('.facetwp-template-static').masonry({
      // options...
      itemSelector: 'article',
    });


    $('.facetwp-template-static').imagesLoaded( function() {
      console.log('images-loaded');
      // images have loaded
      $grid.masonry("reloadItems");
        $grid.masonry("layout");
    });


  jQuery(document).on('facetwp-loaded', function() {
    console.log('facetwp-loaded');

    displaySuchfeld();
    $('.facetwp-template-static').imagesLoaded( function() {
      // images have loaded
      $grid.masonry("reloadItems");
        $grid.masonry("layout");
    });

  });
}

function openFunc() {
jQuery("#hamburger").click(function() {

    $('body').addClass('stop-scrolling');

      jQuery("#nav-wrapper").show();
      jQuery("#nav-wrapper").addClass('padfix');
      jQuery("#nav-wrapper").css('width', '100vw');

});
}
openFunc();
closeFunc();
/* Kontakt Klick */
jQuery(".menu-item-494").click(function() {

  jQuery("#nav-wrapper").hide();

  $('body').removeClass('stop-scrolling');

});


jQuery("#facetbtnon").click(function() {

  if(window.location.pathname == '/' || window.location.pathname == '' || window.location.pathname == '/#' || window.location.pathname == '#'  ) {

    loaderAdd();
    window.location = "/suche";

  } else if (window.location.pathname == '/suche' || window.location.pathname == 'suche' || window.location.pathname == 'suche/#' || window.location.pathname == '/suche#' || window.location.pathname == '/suche/' ) {

      if(jQuery(this).hasClass('active')) {

        loaderAdd();
        window.location = "/";
      }


  } else if (window.location.pathname == '/kammgarn-kultur-listenansicht' || window.location.pathname == 'kammgarn-kultur-listenansicht' || window.location.pathname == 'kammgarn-kultur-listenansicht/#' || window.location.pathname == '/kammgarn-kultur-listenansicht#' || window.location.pathname == '/kammgarn-kultur-listenansicht/' ) {

    loaderAdd();
    window.location = "/kammgarn-kultur-listenansicht-archiv";

  } else if (window.location.pathname == '/kammgarn-kultur-listenansicht-archiv' || window.location.pathname == 'kammgarn-kultur-listenansicht-archiv' || window.location.pathname == 'kammgarn-kultur-listenansicht-archiv/#' || window.location.pathname == '/kammgarn-kultur-listenansicht-archiv#' || window.location.pathname == '/kammgarn-kultur-listenansicht-archiv/' ) {

      if(jQuery(this).hasClass('active')) {

        loaderAdd();
        window.location = "/kammgarn-kultur-listenansicht";
      }


  } else {
      if(jQuery(this).hasClass('active')) {
        FWP.reset();
        jQuery(this).removeClass('active');
        jQuery(".facetcont").hide();
        jQuery("#facetbtnon i").removeClass('fa-toggle-on');
        jQuery("#facetbtnon i").addClass('fa-toggle-off');
        jQuery(".mittelteil").addClass('mittelteilon');
        
      } else {
        FWP.reset();
        jQuery(this).addClass('active');
        jQuery(".facetcont").show();
        jQuery("#facetbtnon i").removeClass('fa-toggle-off');
        jQuery("#facetbtnon i").addClass('fa-toggle-on');
        jQuery(".mittelteil").removeClass('mittelteilon');
      }
    }

});



jQuery(".angebotmenu li").click(function() {
  jQuery('.angebotmenu li').each(function()   {
      $(this).removeClass('active');
   });
  jQuery('.menuclik').each(function()   {
      $(this).hide();
   });
    jQuery('.menuclik.'+ jQuery(this).data('name')).show();
        $(this).addClass('active');

  });




function checknumArtists() {

    /* if adding does work on save: deactivate this! */

    jQuery('.artistlegende').show();
    // check how many items are in .artistlegende ul
    var numArtists = jQuery('.artistlegende ul li').length;
    // check how many acf-row items are in .legendewrap
    var numArtists2 = (jQuery('.legendewrap tr.acf-row:not(.acf-clone)').length);


    // if the number of items in .artistlegende ul is not equal to the number of acf-row items in .legendewrap, display a div in .artistlegende showing how many more have to be added or removed
    if (numArtists > numArtists2) {
      var numDiff = numArtists - numArtists2;
      jQuery('.legendewrap .acf-button.acf-repeater-add-row').show();

      // if numdiff doesn't exist, append. else, fill with html:
      jQuery('.legendewrap .acf-button.acf-repeater-add-row').html('Erfasste Künstler*in übernehmen ('+numArtists2+' von '+ numArtists +' übernommen)');


      jQuery('.artistlegende > ul').hide();
    } else {

      jQuery('.legendewrap .acf-button.acf-repeater-add-row').hide();
      jQuery('.legendewrap .acf-button.acf-repeater-add-row').html('Erfasste Künstler*in übernehmen');
      jQuery('.artistlegende > ul').show();
      jQuery('.legendewrap .acf-row-handle.remove').hide();
    }
  
}




  jQuery( document ).ready( function( $ ) {




    $('.legendewrap .acf-row-handle.order').each(function() {
      $(this).removeClass('order');
    });



    // disable sorting in artist list
    $('.artistlegendeform .acf-row-handle.order').each(function() {
      $(this).removeClass('order');
    });



    jQuery('.legendewrap a.acf-button').click(function() {
      var firstRowHeight = jQuery('.legendewrap tr.acf-row').first().height();
      jQuery('.artistlegende ul li').css('height', firstRowHeight + 'px');
      checknumArtists();
      // check the number again after 1 second:
      setTimeout(checknumArtists, 200);

    });
    if (jQuery('.artistlegende').length && jQuery('.legendewrap').length) {
    jQuery('.artistlegende').css('top', jQuery('.legendewrap').offset().top + 58);
    checknumArtists();
    }

    /* admin sortable artist legende deaktivieren */

    if (jQuery('.legendewrap').length) {
      var firstRowHeight = jQuery('.legendewrap tr.acf-row').first().height();
      jQuery('.artistlegende ul li').css('height', firstRowHeight + 'px');

      jQuery(window).scroll(function(){ 

        jQuery('.artistlegende').css('top', jQuery('.legendewrap').offset().top + 58);
   
        checknumArtists();
  
      });
      


    }


  
/*
    $('div.af-field-type-repeater[data-name="artist_legende"] tbody td.acf-row-handle').off('click mousedown mouseup');
    $('div.af-field-type-repeater[data-name="artist_legende"] tbody').off('click mousedown mouseup');
    $('div.af-field-type-repeater[data-name="artist_legende"]').off('click mousedown mouseup change focusout mouseover showField');

    $('div.af-field-type-repeater[data-name="artist_legende"] tbody td.acf-row-handle').css('background-color', 'black');
*/
    /* // admin sortable */

    repositionLogoLink();


/* suchfeld */
      displaySuchfeld();
/* mobile scroll */


    const articleoz = document.querySelectorAll("article");
    const lazyBackgroundObserver = new IntersectionObserver (function (entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add("shown");
        } else {
          entry.target.classList.remove("shown");
        }
      },{});
    }, { threshold: [0.9] });

      if(  jQuery(window).width() < 767 ) {



        articleoz.forEach ( function (lazyBackground) {
        	lazyBackgroundObserver.observe(lazyBackground);
        });

      }
      $(window).resize(function(){

        /* checken ob OK? von performance */
        if(  jQuery(window).width() < 767 ) {



          articleoz.forEach ( function (lazyBackground) {
          	lazyBackgroundObserver.observe(lazyBackground);
          });

        } else {
          jQuery("article").each(function() {
            jQuery(this).removeClass("shown");
          });
          lazyBackgroundObserver.disconnect();
        }
      });
/* mobile scroll */

      $('body').find(function() {


        $('.infoblock').each(function () {


          if($(this).find('.ozmessagethumb').length) {

            $(this).css('background-image', 'url(' + $(this).find('.ozmessagethumb').data('imgurl') + ')' );
            $(this).css('background-color', 'transparent' );
            $(this).css('background-position', 'center '+$(this).find('.ozmessagethumbpos').data('bgpos') );
            
          }

        });


        if($('.imagegalerieoz').length) {


          var images = $(document).find('.imagegalerieoz').data('images').split(';');
          images =  images.filter(e =>  e);

          var index = 0;

          if( $(document).find('.imagecontaineroz').data('hideimg') !== 1) {
          images.unshift( $(document).find('.imagecontaineroz').data('image') );
        /* $('header').css('background-image', 'url(' + $(document).find('.imagecontaineroz').data('image') + ')');
          } else {
            $('header').css('background-image', 'url(' + images[index] + ')');
            */
          }


          change();

          function change() {
               $('header').css('background-image', 'url(' + images[index] + ')');
             index > (images.length-2) ? index = 0 : index++;
          }

          window.onload = function () {
              setInterval(change, 6000);
          };


        } else {
          $('header').css('background-image', 'url(' + $(document).find('.imagecontaineroz').data('image') + ')');
        }
        if($(document).find('.imagepositionoz').data('position')) {
          $('header').css('background-position', $(document).find('.imagepositionoz').data('position') );
        }
        /* console.log($(document).find('.imagecontaineroz').data('image')); */
      }

    );

    if (window.location.pathname == '/suche' || window.location.pathname == 'suche' || window.location.pathname == 'suche/#' || window.location.pathname == '/suche#' || window.location.pathname == '/suche/') {
      jQuery("#facetbtnon").addClass('active');
      jQuery(".mittelteil").removeClass('mittelteilon');
      jQuery(".facetcont").show();
      jQuery("#facetbtnon i").removeClass('fa-toggle-off');
      jQuery("#facetbtnon i").addClass('fa-toggle-on');
    }


    subnavFunction();


  });
  function closeFunc() {
    jQuery("#close").click(function() {

        jQuery('body').removeClass('stop-scrolling');
        jQuery("#nav-wrapper").removeClass('padfix');
        jQuery("#nav-wrapper").css('width', '0px');

      if(!jQuery('.custom-event-calendar').length) {
       FWP.reset();
     }
    });
    jQuery("#closes").click(function() {

        jQuery('#subnav-wrapper').css('width', '0px');
        jQuery('body').removeClass('stop-scrolling');
        jQuery("#nav-wrapper").removeClass('padfix');
        jQuery("#nav-wrapper").css('width', '0px');

      FWP.reset();

    });

    jQuery(".menucloselink").click(function() {

      jQuery('body').removeClass('stop-scrolling');
      jQuery("#nav-wrapper").removeClass('padfix');
      jQuery("#nav-wrapper").css('width', '0px');

      openFunc();
    });
  }

  var backvar = jQuery('#nav-wrapper').html();
  function backvarFunction() {
    jQuery('#subnav-wrapper').css('width', '0px');
    subnavFunction();
    closeFunc();
  }
  // launch backvarfunction on #closesub.backvarf click
  jQuery("#closesub.backvarf").on('click', function() {
    backvarFunction();
  });
  function subnavFunction() {
  jQuery('.hassubnav').each(function() {

    jQuery(this).find('.subclik').click(function() {


    //  jQuery('#nav-wrapper').find('.menudiv').hide();

      var content = jQuery(this).parent().find('.submenu').html();

      var cont = '<div class="menutitle col-md-6"><a href="';

      var cont2 = '">';

      var cont3 = '</a></div><nav id="nav-main" class="nav-main col-md-6" role="navigation">'+ content + '</nav>';



    //  jQuery('#nav-wrapper').html(cont1 + jQuery(this).parent().find('.submenu').data('link') + cont2 + cont3);

      jQuery('#subnav-wrapper .menudiv .row').html(cont + jQuery(this).data('link') + cont2 + jQuery(this).parent().find('.submenu').data('link') + cont3);
      jQuery('#subnav-wrapper').css('display','block');
      jQuery('#subnav-wrapper').css('z-index','2000');
      jQuery('#subnav-wrapper').css('width','100vw');
//      jQuery('#nav-wrapper').css('transform', 'translateX(-100%)');
//      jQuery('#nav-wrapper').animate({left: '100vw'});

    });
  });
  }

  jQuery('a#tischreservieren').click(function() {
    jQuery('#rescontent').show();
    $('body').addClass('stop-scrolling');

  });
  jQuery("#closeres").click(function() {

    jQuery("#rescontent").hide();
    $('body').removeClass('stop-scrolling');


  });

  jQuery('a#kontaktformular').click(function() {
    jQuery('#rescontentkontakt').show();
    $('body').addClass('stop-scrolling');

  });
  jQuery('a#kontaktformularbeiz').click(function() {
    jQuery('#rescontentkontaktbeiz').show();
    $('body').addClass('stop-scrolling');

  });
  jQuery("#closeresk").click(function() {

    jQuery("#rescontentkontakt").hide();
    $('body').removeClass('stop-scrolling');


  });
  jQuery("#closereskbeiz").click(function() {

    jQuery("#rescontentkontaktbeiz").hide();
    $('body').removeClass('stop-scrolling');


  });


function teamclick() {
  jQuery('article.teamexp').click(function() {

    if(jQuery(this).hasClass('active')) {

      jQuery(this).find('.hiddenteam').hide();
      jQuery(this).removeClass('active');
      $grid.masonry("reloadItems");
        $grid.masonry("layout");
    } else {
    jQuery(this).find('.hiddenteam').show();
    jQuery(this).addClass('active');
    $grid.masonry("reloadItems");
      $grid.masonry("layout");
    }

  });
}

  jQuery( document ).ready( function( $ ) {

    teamclick();

        $(".facetwp-facet-galerieyes .facetwp-checkbox span.facetwp-display-value").html("Galerie");
    (function($) {
        document.addEventListener('facetwp-loaded', function() {
          teamclick();
          $(".facetwp-facet-galerieyes .facetwp-checkbox span.facetwp-display-value").html("Galerie");
         });
    })(jQuery);

    resizeFloater();

  });
  $(window).resize(function(){

    resizeFloater();
    repositionLogoLink();
    thumbnailheaderheight();

  });


function repositionLogoLink() {

  jQuery('#mobilelogolink').css('top', jQuery('header').height() - 70);

}

var fwidth = 0;


function resizeFloater() {
  if(  jQuery(window).width() < 1101 ) {

    jQuery(jQuery(".infofloater").detach()).appendTo(".infofloatermobile");


  } 

  if(  jQuery(window).width() < 767 ) {

  fwidth = 0;
  jQuery( '.facetwp-facet-genres' ).each( function() {
      $('.facetwp-facet-genres .facetwp-checkbox').each(function() { fwidth += $(this).width() + 35;

      });
  });
  jQuery( '.facetwp-facet-genres_nochildren' ).each( function() {
    $('.facetwp-facet-genres_nochildren .facetwp-checkbox').each(function() { fwidth += $(this).width() + 35;

    });
});
  jQuery( '.facetwp-facet-genres').width(fwidth);

  fwidth = 0;
  jQuery( '.facetwp-facet-lokal' ).each( function() {
      $('.facetwp-facet-lokal .facetwp-checkbox').each(function() { fwidth += $(this).width() + 35;

      });
  });
  jQuery( '.facetwp-facet-lokal').width(fwidth);

} else {

    jQuery( '.facetwp-facet-genres').width('100%');
    jQuery( '.facetwp-facet-lokal').width('100%');


}
  if(jQuery('#facetbtnon').hasClass('active')) {
    jQuery( '.facetwp-facet-genres' ).parent().show();
    jQuery( '.facetwp-facet-genres_nochildren' ).parent().show();
    jQuery( '.facetwp-facet-lokal' ).parent().show();
  } else {
    jQuery( '.facetwp-facet-genres' ).parent().hide();
    jQuery( '.facetwp-facet-genres_nochildren' ).parent().hide();
    jQuery( '.facetwp-facet-lokal' ).parent().hide();
  }
}

/*
function heightScript() {
  jQuery('.post-type-event .article-content').each( function() {

        jQuery(this).find('h1.article-h1').height();
        jQuery(this).find('h2').height();
        jQuery(this).find('.article-body').children('p:first-child').height();

  });
}
*/


(function($) {
    $(document).on('facetwp-refresh', function() {
      loaderAdd();
    });
    $(document).on('facetwp-loaded', function() {
      loaderRem();
    });
})(jQuery);

function loaderAdd() {
    $('.fladen').show();

}
function loaderRem() {
    $('.fladen').hide();
    

  jQuery( document ).ready( function( $ ) {
    adminbuttonfunction();
  });

}

lightGallery(document.getElementById('animated-thumbnails'), {

        plugins: [lgThumbnail]
});


jQuery("#gallerybutton").on("click", function() {
    jQuery("#animated-thumbnails a:first-child > img").trigger("click");
});


/*
var observer = new IntersectionObserver(function(entries) {
	if(entries[0].isIntersecting === true)
		console.log('Element is fully visible in screen');
}, { threshold: [1] });

observer.observe(document.querySelector("#main-container"));

*/


function displaySuchfeld() {
  $('.facetwp-facet-suche .facetwp-input-wrap input.facetwp-search').blur(function() {
  $(this).parent().children('i').removeClass("ozfocus");
})
.focus(function() {
  $(this).parent().children('i').addClass("ozfocus");
});

}

function thumbnailheaderheight() {
if( $(document).find('.nothumbnail').data('nothumb') == true) {
          
  $(document).find('header').removeClass('singleheader');
  $(document).find('.infofloater').addClass('notop');

 }
}

thumbnailheaderheight();

mybutton = document.getElementById("myBtn");
fixedheader = document.getElementById("floatingheader");
header = document.getElementById("header");
var sticky = header.offsetHeight;

// When the user scrolls down 20px from the top of the document, show the button

function scrollFunction() {
  if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }


  if (document.body.scrollTop > sticky + 83 || document.documentElement.scrollTop > sticky + 83) {
    fixedheader.style.display = "block";
  } else {
    fixedheader.style.display = "none";
  }

}
// when the user clicks #myBtn, run topFunction
mybutton.addEventListener("click", topFunction);

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

var alreadydone = 0;
jQuery(window).scroll(function(){
  scrollFunction();
    // This is then function used to detect if the element is scrolled into view
    function elementScrolled(elem)
    {
        var docViewTop = jQuery(window).scrollTop();
        var docViewBottom = docViewTop + jQuery(window).height();
        var elemTop = jQuery(elem).offset().top;
        return ((elemTop <= docViewBottom) && (elemTop >= docViewTop));
    }

    // This is where we use the function to detect if ".box2" is scrolled into view, and when it is add the class ".animated" to the <p> child element

    if(  jQuery(window).width() < 769 && alreadydone != 1) {

      if ( $( ".wochenmenuwrapper" ).length ) {
        if(elementScrolled('.wochenmenuwrapper')) {
          jQuery( ".wochenmenuwrapper" ).animate({scrollLeft: 100}, 1500);
          alreadydone = 1;
        }
      }
      if ( $( ".facetcont.genres" ).length ) {

        if(elementScrolled('.facetcont.genres')) {
          jQuery( ".facetcont.genres" ).animate({scrollLeft: 100}, 1500);
          alreadydone = 1;
        }
      }
    }
});




/* Swipe Script */


(function (window, document) {

    'use strict';

    // patch CustomEvent to allow constructor creation (IE/Chrome)
    if (typeof window.CustomEvent !== 'function') {

        window.CustomEvent = function (event, params) {

            params = params || { bubbles: false, cancelable: false, detail: undefined };

            var evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        };

        window.CustomEvent.prototype = window.Event.prototype;
    }

    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);
    document.addEventListener('touchend', handleTouchEnd, false);

    var xDown = null;
    var yDown = null;
    var xDiff = null;
    var yDiff = null;
    var timeDown = null;
    var startEl = null;

    /**
     * Fires swiped event if swipe detected on touchend
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleTouchEnd(e) {

        // if the user released on a different target, cancel!
        if (startEl !== e.target) return;

        var swipeThreshold = parseInt(getNearestAttribute(startEl, 'data-swipe-threshold', '20'), 10); // default 20px
        var swipeTimeout = parseInt(getNearestAttribute(startEl, 'data-swipe-timeout', '500'), 10);    // default 500ms
        var timeDiff = Date.now() - timeDown;
        var eventType = '';
        var changedTouches = e.changedTouches || e.touches || [];

        if (Math.abs(xDiff) > Math.abs(yDiff)) { // most significant
            if (Math.abs(xDiff) > swipeThreshold && timeDiff < swipeTimeout) {
                if (xDiff > 0) {
                    eventType = 'swiped-left';
                }
                else {
                    eventType = 'swiped-right';
                }
            }
        }
        else if (Math.abs(yDiff) > swipeThreshold && timeDiff < swipeTimeout) {
            if (yDiff > 0) {
                eventType = 'swiped-up';
            }
            else {
                eventType = 'swiped-down';
            }
        }

        if (eventType !== '') {

            var eventData = {
                dir: eventType.replace(/swiped-/, ''),
                touchType: (changedTouches[0] || {}).touchType || 'direct',
                xStart: parseInt(xDown, 10),
                xEnd: parseInt((changedTouches[0] || {}).clientX || -1, 10),
                yStart: parseInt(yDown, 10),
                yEnd: parseInt((changedTouches[0] || {}).clientY || -1, 10)
            };

            // fire `swiped` event event on the element that started the swipe
            startEl.dispatchEvent(new CustomEvent('swiped', { bubbles: true, cancelable: true, detail: eventData }));

            // fire `swiped-dir` event on the element that started the swipe
            startEl.dispatchEvent(new CustomEvent(eventType, { bubbles: true, cancelable: true, detail: eventData }));
        }

        // reset values
        xDown = null;
        yDown = null;
        timeDown = null;
    }

    /**
     * Records current location on touchstart event
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleTouchStart(e) {

        // if the element has data-swipe-ignore="true" we stop listening for swipe events
        if (e.target.getAttribute('data-swipe-ignore') === 'true') return;

        startEl = e.target;

        timeDown = Date.now();
        xDown = e.touches[0].clientX;
        yDown = e.touches[0].clientY;
        xDiff = 0;
        yDiff = 0;
    }

    /**
     * Records location diff in px on touchmove event
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleTouchMove(e) {

        if (!xDown || !yDown) return;

        var xUp = e.touches[0].clientX;
        var yUp = e.touches[0].clientY;

        xDiff = xDown - xUp;
        yDiff = yDown - yUp;
    }

    /**
     * Gets attribute off HTML element or nearest parent
     * @param {object} el - HTML element to retrieve attribute from
     * @param {string} attributeName - name of the attribute
     * @param {any} defaultValue - default value to return if no match found
     * @returns {any} attribute value or defaultValue
     */
    function getNearestAttribute(el, attributeName, defaultValue) {

        // walk up the dom tree looking for attributeName
        while (el && el !== document.documentElement) {

            var attributeValue = el.getAttribute(attributeName);

            if (attributeValue) {
                return attributeValue;
            }

            el = el.parentNode;
        }

        return defaultValue;
    }

}(window, document));


document.addEventListener('swiped-right', function(e) {
  if(jQuery('#subnav-wrapper').css('width') != '0px') {
    jQuery('#subnav-wrapper').css('width', '0px');
    subnavFunction();
    closeFunc();

  } else if (jQuery('#nav-wrapper').css('width') != '0px') {

    jQuery('#subnav-wrapper').css('width', '0px');
    jQuery('body').removeClass('stop-scrolling');
    jQuery("#nav-wrapper").removeClass('padfix');
    jQuery("#nav-wrapper").css('width', '0px');

    FWP.reset();

  }

});

})(jQuery);

function copyIcsToClipboard(button) {
  const baseUrl = window.location.origin;
  const link = baseUrl + button.getAttribute('data-icslink');
  
  navigator.clipboard.writeText(link).then(() => {
      alert('Link kopiert');
  }).catch(err => {
      console.error('Failed to copy: ', err);
  });
}

